<template>
  <div class="gnb flexB" ref="gnbContainer" style="z-index: 2000;box-shadow: 0px 1px 3px #e1e1e1;">

    <h2 class="top-bar-system-title">
      <div style="padding-left: 0px; width: 190px;" >
        <a href="javascript:void(0);" @click = "navigateToControlUser" class="top_site_bar_logo">
          <img :src="require(`/src/assets${getImageSrc(accountId , 'gnb')}`)" style="width: 100%;">
        </a>
        </div>

      <div class="top-system-title">
        <img
        v-if="accountId !='dev' && accountId != 'demo'"
        :src="require(`/src/assets${getImageSrc(accountId , 'gnbTopImage')}`)"
        >
        <!-- acoountId 별로 문구 지정 -->
        <h2 v-if="accountId !='dev' && accountId != 'demo'">
          {{getContentString(this.accountId , 'title')}}
        </h2>

      </div>
    </h2>
    <div class="top-menu">
        <ul>
            <li v-for="(item, index) in menuItems"
                :key="index"
                :class="{ 'menu-item': true, 'active': activeIndex === index }"
                >
                <!-- @click="setActive(item)" -->
                <router-link :to="{ name: item.link }"  @click.native="setActive(item)">
                  <!-- <router-link :to="{ name: item.link }" @click.native="setCurrentGNB(item)"> -->
                  <img :src="selectMenu == item.name ? item.iconColor : item.iconBw" class="c_active" :alt="item.name + ' color icon'">
                  <!-- <img :src="selectMenu.name == item.name ? item.iconColor : item.iconBw" class="c_active" :alt="item.name + ' color icon'"> -->
                  <span class="menu-text">{{ item.displayName }}</span>
                </router-link>
            </li>
        </ul>
    </div>
    <div class="loginInfo flex">
      <router-link :to="{ name: 'Information' }" class="sitebox" style="margin-right: 20px;">
        <span style="color: #0080FF; background:#fff;">No.{{ event.viewId != null ? event.viewId : event.eventId }}</span>
        <span style="color: #101010; background:#fff;">{{ event.title? event.title : "-" }}</span>
      </router-link>
      <img src="@/assets/images/ico_user.png" alt="user" />
      <div>
        <a href="javascript:void(0);" @click="toggleSubMenu" class="drop-menu-btn" id="info">
          <p class="loginInfo-name" @click.stop="toggleSubMenu"><span style="color:#0080FF;background:#fff;margin:0;">{{ departmentName }}</span>│{{ name }}</p>
          <img src="@/assets/images/icon_svg/dropdown-menu-btn.png" alt="user" @click.stop="toggleSubMenu" />
        </a>
        <div class="drop-menu" v-if="isSubMenuVisible" id="infoSub">
          <div class="drop-menu-profile">
            <img src="@/assets/images/ico_user.png" alt="user" />
            <div class="profile-info">
              <p class="deptNm"><span style="color:#0080FF;background:#fff;margin:0;">{{ departmentName }}</span>{{ name }}</p>
              <p class="gnb_email">{{ loginId }}</p>
            </div>
          </div>
          <ul>
            <li @click="navigateToProfileEdit"><a href="javascript:void(0);"><img src="@/assets/images/icon_svg/profile.png" alt="user" />개인정보 수정</a></li>
            <li><a href="javascript:void(0);" @click="navigateToControlUser"><img src="@/assets/images/icon_svg/siteOut.png" alt="user" />현장나가기</a></li>
            <li><button class="table" @click="logout"><img src="@/assets/images/icon_svg/logout.png" alt="user" />{{ $t("btn-logout") }}</button></li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</template>
<style lang="scss">
.title{
  background: none !important;
}
.top_site_bar_logo > img{width: 100%;}
.top-bar-site-logo{
  display: flex;
    align-items: center;
    margin: 0;
}
.top-system-title{
  margin-left: 60px;
  width: 250px;
  padding-top: 0px;
  img{
    width: max-content;
  }
  h2{
    font-size: 1.8rem;
    font-weight: 700;
    color: #101010;
    margin: 0;
  }
}
.gnb {
    min-width: 100%;
    height: 50px;
    background: #fff;
    font-size: 1.2rem;
    color: #808080;
    padding: 0 30px 0 30px;
    border-bottom: 0px solid #d3d3d3;
    position: absolute;
    top: 0;
    left: 0;
}
.top-bar-system-title{
  display: flex;
  align-items: center;
  padding-left: 30px;
  font-size: 2rem;
  margin: 0;
  font-weight: bold;
  letter-spacing: -1px;
}
.sitebox{
  overflow:hidden;
  text-overflow:ellipsis;
  white-space:nowrap;
  display:inline-block;
}
.siteInfo span:nth-child(2):before{border-left: 0px solid #808080;}
.loginInfo > .deptNm{background: #0080FF;color: #fff;padding: 2px 10px;border-radius: 10px;margin-right: 5px;margin-bottom: 0 !important;}
.loginInfo > img{
  width: 28px;
  height: 28px;
  border-radius: 50%;
  overflow: hidden;
  border: 1px solid #808080;
  margin: 0 10px;
}
.loginInfo-name{font-size: 14px;margin-right: 5px;}

.userProfile > img{width: 30px;height: 30px;}
.drop-menu-btn{position: relative;display: flex;align-items: center;}
.drop-menu{position: absolute;right: 30px ;background: #fff;border-radius: 5px;box-shadow: 1px 1px 5px #939393;top: 50px;width: 230px;}
.drop-menu > .drop-menu-profile{display: flex;justify-content: space-between;align-items: center;border-bottom: 1px solid #ddd;padding: 15px;}
.drop-menu > .drop-menu-profile > img{width: 50px;}
.drop-menu > .drop-menu-profile > .profile-info{text-align: right;}
.drop-menu > .drop-menu-profile > .profile-info > p{font-size: 14px;color: #454545;margin-bottom: 5px;}
.drop-menu > .drop-menu-profile > .profile-info > p > span{background: #0080FF;color: #fff;border-radius: 10px;padding: 2px 10px;font-size: 12px;}
.drop-menu > .drop-menu-profile > .profile-info > .gnb_email{font-size: 12px;color: #969696;margin: 0;}
.drop-menu > ul > li{border-bottom: 1px solid #ddd;text-align: left;}
.drop-menu > ul > li:hover{background: #eee;}
.drop-menu > ul > li:nth-child(3){border: 0;}
.drop-menu > ul > li > a{font-size: 14px !important;color: #858585;padding: 15px 20px;width: 100%;display: block;}
.drop-menu > ul > li > a > img{margin-right: 10px;vertical-align: sub;}
.drop-menu > ul > li > button{border: 0;padding: 15px 20px;font-size: 14px !important;font-weight: 300;color: #858585;height: auto;text-align: left;width: 100%;height: auto !important;}
.drop-menu > ul > li > button > img{margin-right: 10px;}

.top-menu{position: absolute;left: 50%;top: 50%;transform: translate(-50%, -50%);}
.top-menu > ul{display: flex;align-items: center;}
.top-menu > ul > li{margin: 0 20px; position: relative;}
.top-menu > ul > li > a{display: block;width: 20px;}
.top-menu > ul > li > a > .c_active{width: 100%;}
.top-menu > ul > li > a > .b_active{width: 100%;opacity: 0.7;}
.menu-text {
  display: none;
  position: absolute;
  top: 120%;
  left: 50%;
  transform: translateX(-50%);
  background-color: #0080ffd9;
  color: white;
  padding: 5px;
  border-radius: 3px;
  white-space: nowrap;
  font-size: 1.2rem;
}

.menu-item:hover .menu-text {
  display: block;
}

</style>
<script>
import { mapState } from "vuex";
import moment from "moment";
import { fetchEvent } from "@/api/event";
import {getImageSrc , getContent} from "@/util/common";

export default {
  data() {
    return {
      moment: moment,
      subMenu: "",
      menuName:"",
      option: [],
      event: {},
      department:{},
      eventId : null,
      backgrounds: ["#0B0A11", "#ffffff"], // 배경 색상 배열
      currentBackgroundIndex: 0,

      isSubMenuVisible: false,
      menuItems: [
        { name: 'SkyView', link: 'SkyView', displayName: '모니터링', iconColor: require('@/assets/images/top_menu_icon_c1.png'), iconBw: require('@/assets/images/top_menu_icon_b1.png') , isCheck : false},
        { name: 'VOD', link: 'VOD',displayName: '데이터', iconColor: require('@/assets/images/top_menu_icon_c2.png'), iconBw: require('@/assets/images/top_menu_icon_b2.png'), isCheck : false },
        { name: 'Mission', link: 'Mission',displayName: '미션제어', iconColor: require('@/assets/images/top_menu_icon_c3.png'), iconBw: require('@/assets/images/top_menu_icon_b3.png'), isCheck : false },
        { name: 'StationInfo', link: 'StationInfo',displayName: '스테이션', iconColor: require('@/assets/images/top_menu_icon_c4.png'), iconBw: require('@/assets/images/top_menu_icon_b4.png'), isCheck : false },
        // { name: 'AiMonitoring', link: 'AiMonitoring',displayName: 'AI모니터링', iconColor: require('@/assets/images/top_menu_icon_c5.png'), iconBw: require('@/assets/images/top_menu_icon_b5.png'), isCheck : false },
      ],
      activeIndex: null,
      selectMenu : null
    };
  },
  computed: {
    ...mapState({
      isLogin : state => state.store.isLogin,
      name : state => state.store.name,
      accountId : state => state.store.accountId,
      loginId : state => state.store.loginId,
      ownerWork : state => state.store.ownerWork,
      options : state => state.store.options,
      navbarState : state => state.store.navbarState,
      subMenuState : state => state.store.subMenuState,
      departmentName : state => state.store.departmentName,
      currentLang : state => state.store.currentLang,
    }),
  },
  created() {
    if(localStorage.getItem("selectMenu") == undefined || localStorage.getItem("selectMenu") == null) {
      localStorage.setItem("selectMenu", "SkyView")
    }
    this.selectMenu = localStorage.getItem("selectMenu")
    this.eventId = this.$route.params.eventId;

    this.activeLink = this.$route.path;
  },
  mounted() {
    //mouseover 상태바(아래 URL) 없애기
    this.$nextTick(function (){
      var atags = this.$refs.gnbContainer.querySelectorAll('a');

      atags.forEach(el => el.addEventListener('mouseover', function() {
        var href = this.getAttribute('href') || this.getAttribute("data-href");
        this.addEventListener('click', function () {
          window.location.href = href;
        })
        this.setAttribute('data-href', href)//href에 지정된 링크 연결을 유지 시킵니다.
        this.removeAttribute('href');
      }))
    })
    //this.setNavbarAndTabByRouteName(this.$route.name);
    this.handleMenu();
    this.getEventDetail();
    // this.beforeUpdate();
  },
  watch: {
    $route (to){
      this.menuName = to.title;
      this.getEventDetail();
      //this.setNavbarAndTabByRouteName(to.name);
      this.activeLink = to.path;
    },
    subMenuState: function () {
      this.handleMenu();
    },
    navbarState: function () {
      this.handleMenu();
    },
  },
  filters: {
    moment : function (date){
      //브라우저 언어설정에 따른 양식 변화
      return moment(date).lang(navigator.language).format('LL');
    }
  },
  methods: {
    setCurrentGNB(menuItem) {
      this.selectMenu= menuItem

    },
    getEventDetail() {
      if(this.eventId == undefined) {
        return;
      }
      fetchEvent(this.eventId).then((res) => {
        this.event = res.data.data;
        this.department = res.data.data.managerDepartment;
      });
    },
    navigateToControlUser() {
      this.$router.push(`/${this.accountId}/eventControl/liveListControlUser`);
    },
    handleRefresh() {
      this.$router.go(this.$router.currentRoute);
    },
    handleMenu() {
      this.option = this.options[this.navbarState - 1];
      this.subMenu = this.option[this.subMenuState].label;
    },
    handleSubMenu() {
      this.$router.push(this.subMenu);

      var container = document.querySelectorAll(".el-menu-item");
      var index = this.navbarState - 1;
      var menu = container.item(index);
      if (menu.classList.contains("router-link-active") == false) {
      menu.classList.add("router-link-active");
      }
    },
    logout() {
      let id = this.accountId;
      this.$store.dispatch("store/LOGOUT");
      this.$router.push(`/${id}/login`);
    },
    toggleSubMenu() {
      this.isSubMenuVisible = !this.isSubMenuVisible;
      if (this.isSubMenuVisible) {
        // 윈도우 클릭 이벤트 추가
        window.addEventListener('click', this.closeSubMenuOnClickOutside);
      }
      else{window.removeEventListener('click', this.closeSubMenuOnClickOutside);}
    },
    closeSubMenuOnClickOutside(event) {
      // 만약 클릭된 요소가 서브 메뉴나 해당 토글 버튼이 아니라면 서브 메뉴를 닫습니다.
      const subMenu = document.getElementById('infoSub'); // 서브 메뉴의 ID를 사용하셔야 합니다.
      const toggleButton = document.getElementById('info'); // 토글 버튼의 ID를 사용하셔야 합니다.

      // 클릭된 요소가 서브 메뉴 안에 있는지 확인
      const clickedInsideSubMenu = subMenu.contains(event.target);

      if (!clickedInsideSubMenu && event.target !== toggleButton) {
        this.isSubMenuVisible = false;
        // 다른 메뉴의 활성화 상태도 여기서 해제해야 한다면 추가해주세요.
        this.isSubMenuVisible2 = false;
        // this.isSubMenuVisible3 = false;

        // 윈도우 클릭 이벤트 제거
        window.removeEventListener('click', this.closeSubMenuOnClickOutside);
      }
    },
    moveToUserEdit(){
      this.changeShowUserOption();
      if(this.eventId === undefined){
        this.$router.push({path : `/${this.accountId}/user/userEdit`});
      }else{
        this.$router.push({path : `/${this.accountId}/eventControl/${this.eventId}/userEdit`});
      }
    },
    setActive(item) {
      localStorage.setItem("selectMenu", item.name)
      this.activeLink = item.link;
      this.selectMenu = item.name;
      this.activeIndex = this.menuItems.indexOf(item);
    },
    isActive(link) {
      return this.$route.path === link;
    },
    navigateToProfileEdit() {
      this.$router.push({ name: 'profileEdit' });
    },
    getImageSrc(accountId , type){
        return getImageSrc(accountId,type)
    },
    getContentString(accountId , type){
       return getContent(accountId,type)
    },
  },

};
</script>
