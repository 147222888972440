<template>
  <div class="lnb" ref="lnbNav"> <!--v-bind:style="[isAdmin ? {'background' : '#1e1e1e' }:{'background' : '#081c2c'}]">--><!--style="background: #085b48;">-->

    <el-row class="mainMenu"> <!--v-bind:class="[isAdmin ? 'mainMenu_admin':'mainMenu']">-->
      <!-- <div class="lnbTop">
        <div class="lnbLogo" v-if="event != null">

                    <span class="bold" style="display: block; color: #ffad43; margin-bottom: 5px;">No.{{ event.viewId != null ? event.viewId : event.eventId }}</span>


          <a :href="getHomeUrl()" >
            <img v-if="accountId == 'komipo'" src="@/assets/images/LNB_new_rw_logo.png" alt="logo" style="width: 194px;" />
            <div v-else style="width: 50px;height:50px; text-align: center;padding-top: 15px;padding-left: 5px;">
              <img src="@/assets/images/logo_icon.png" alt="logo">
            </div>

           <img v-else src="@/assets/images/LNB_new_logo.png" alt="logo" />
           <img v-else src="@/assets/images/ic_user_logo_wh.svg" alt="logo" style="width: 20rem; fill: white;"/>
           <img :src="getMissingImg()" alt="icon"
                             style="width: 6rem; height: 6rem; border: 3px solid saddlebrown; border-radius: 2rem;"/>
            <h1>드론운영관제시스템</h1>
            <img src="@/assets/images/ic_logo_dronework.svg" alt="logo"
                 style="width: 13rem; height: auto;"/>
          </a>

          <div style="display: block; margin-top: 10px;">
                      <span style="font-size: 12px;"> 총 {{elapsedDay}}일</span>
                      <span v-if="event.status == 'Open' " style="font-size: 12px; color: white; background: red; border: 1px solid #ebebeb; border-radius: 12px; padding: 1px 5px; margin-left: 6px;">진행중</span>
                      <span v-if="event.status == 'Close' " style="font-size: 12px; color: white; background: black; border: 1px solid #ebebeb; border-radius: 12px; padding: 1px 5px; margin-left: 6px;">종료</span>
                      <span v-if="event.status == 'Ready' " style="font-size: 12px; color: white; background: gray; border: 1px solid #ebebeb; border-radius: 12px; padding: 1px 5px; margin-left: 6px;">대기</span>
                      &lt;!&ndash;            <img v-if="event.status == 'Ready' " class="statusImg" src="@/assets/images/icon_svg/ic_waiting.svg" />
                                  <img v-else-if="event.status == 'Close'" class="statusImg" src="@/assets/images/icon_svg/ic_close.svg" />
                                  <img v-else-if="event.status == 'Open'" class="statusImg" src="@/assets/images/icon_svg/ic_on_going.svg" />&ndash;&gt;
                      &lt;!&ndash;            <span class="bold" style="display: block; ">{{ event.title? event.title : "-" }}</span>&ndash;&gt;
                    </div>
        </div>
        <a :href="getHomeUrl()" class="lnbLogo">
                  <img src="@/assets/images/LNB_logo.svg" alt="logo" />
                </a>

      </div> -->
      <div class="data-content-menu">
        <el-col :span="12">
          <el-menu
              :router="false"
              :default-active="selectIndex"
              :unique-opened="true"
              class="el-menu-vertical-demo"
              @open="leftOpen"
              @close="leftClose"
          >

            <!-- 드론경로 계획 -->
            <el-submenu index="1">
              <template slot="title">
                <img src="@/assets/images/LNB_mapping.png" alt="icon" />
                <span>{{$t("site-control-menu-plan")}}</span>
              </template>
              <el-menu-item index="Mission" v-if="accountId!='komipo'">
                <router-link :to="{ name: 'Mission'}">  {{$t("site-control-mission-sub-list")}} </router-link>
              </el-menu-item>
              <!-- 맵핑 작업 완료 시 해제 -->
             <el-menu-item index="Mapping">
                <router-link :to="{ name: 'Mapping' }"> {{ $t("site-control-mapping-sub-list") }} </router-link>
              </el-menu-item>
            </el-submenu>

            <!-- 스테이션 계획 -->
            <el-submenu index="2">
              <template slot="title">
                <img src="@/assets/images/LNB_schedule.png" alt="icon" />
                <span>{{$t("site-control-menu-station-plan")}}</span>
              </template>

              <el-menu-item route="/" index="SchedulePlan" v-if="accountId!='komipo'">
                <router-link :to="{ name: 'SchedulePlan'}">
                  {{$t("site-control-menu-schedule")}}
                </router-link>
              </el-menu-item>

              <el-menu-item index="ScheduleHistory" v-if="accountId!='komipo'">
                <router-link
                :to="{
                  name: 'ScheduleHistory',
                  query: { page: 1 }
                  }">
                  {{$t("site-control-menu-schedule-history")}}
                </router-link>
              </el-menu-item>
            </el-submenu>
          </el-menu>
        </el-col>
      </div>

      <div class="btBox">
        <a  v-if="accountId == 'forest'"  href="https://www.forest.go.kr/" target="_blank">
          <img
              src="../assets/images/LNB_logo_korea_forest.png"
              alt="logo"
              class="bt_logo"
              style="border-radius: 4px;"
          />
        </a>
        <a  class="teixonLogo" v-if="accountId == 'forest'"  href="https://www.teixon.com/" target="_blank" style="text-align: right; padding: 0 10px; margin-bottom: 10px;">
          <span style="font-size: 12px;">Software by</span>
          <img
              src="../assets/images/teixon_new_logo_wh.svg"
              alt="logo"
              style="width: 80px;margin: 0 6px; padding-bottom: 4px;"
          />
        </a>
        <!-- teixon logo -->
        <!-- <a v-if="accountId != 'forest'" href="http://www.teixon.com" target="_blank" style="border-top: 1px solid #707070;background: #101010;padding: 5px ">
          <img
              src="../assets/images/teixon_logo.png"
              alt="logo"
              class="bt_logo"
              style="width: 9rem;margin:5px auto"
          /></a> -->
      </div>
    </el-row>
  </div>
</template>
<script>
import { mapState } from "vuex";
import lang from "@/mixins/lang";
import { fetchEvent } from "@/api/event";

import thum1 from "@/assets/images/thum_s1.png";
import thum2 from "@/assets/images/thum_s2.png";
import thum3 from "@/assets/images/thum_s3.png";
import thum4 from "@/assets/images/thum_s4.png";
import thum5 from "@/assets/images/thum_s5.png";
import thum6 from "@/assets/images/thum_s6.png";
import moment from "moment/moment";

export default {
  mixins: [lang],
  data() {
    return {
      isCollapse: false,
      label: "",
      event: null,
      eventId: null,
      lastEventId: null,
      thumUrl : null,
      images:[thum1,thum2,thum3,thum4,thum5,thum6],
      selectIndex : null,
      elapsedDay : 0,
      isUserType: null,
      isSubMenuVisible: false,
      isSubMenuVisible2: false,
    };
  },
  computed: {
    ...mapState({
      navbarState: state => state.store.navbarState,
      subMenuState: state => state.store.subMenuState,
      isAdmin: state => state.store.isAdmin,
      userType: state => state.store.userType,
      accountId: state => state.store.accountId,
      name : state => state.store.name,
      loginId : state => state.store.loginId,
      departmentName : state => state.store.departmentName,
      isLogin : state => state.store.isLogin,
      ownerWork : state => state.store.ownerWork,
      options : state => state.store.options,
      currentLang : state => state.store.currentLang,

    }),
    currentLang: {
      get() {
        return this.$store.state.store.currentLang;
      },
      set(value) {
        this.$store.commit("store/langChange", value);
        //TODO 언어 변경시 이동 제거
        //this.$router.go();
      },
    },
  },
  created() {
    this.eventId = this.$route.params.eventId;
    this.isUserType = localStorage.getItem("userType");
  },
  watch:{
    selectIndex(value){
      switch (value) {
        case "LiveMapOpticsDetail" :
          this.selectIndex = "LiveMap"
          break;
        case "LiveMapThermalDetail" :
          this.selectIndex = "LiveMap"
          break;
        case "MissionDetail" :
          this.selectIndex = "Mission"
          break;

      }
    },
    $route (to){
      this.selectIndex = to.name;
    }
  },
  mounted(){

    //mouseover 상태바(아래 URL) 없애기
    this.$nextTick(function (){
      var atags = this.$refs.lnbNav.querySelectorAll('a');

      atags.forEach(el => el.addEventListener('mouseover', function() {
        var href = this.getAttribute('href') || this.getAttribute("data-href");
        this.addEventListener('click', function () {
          window.location.href = href;
        })
        this.setAttribute('data-href', href)//href에 지정된 링크 연결을 유지 시킵니다.
        this.removeAttribute('href');
      }))
    })

    this.getEventDetail();
    this.selectIndex = this.$route.name;
  },
  methods: {
    time(){
      let now = new Date();
      let open = new Date(this.event.openDate);
      let elapsedMSec;

      if(this.event.status == "Close"){
        let close = new Date(this.event.closeDate);
        let open = new Date(this.event.openDate);

        elapsedMSec = close.getTime() - open.getTime();

      }else if(this.event.status == "Ready"){
        elapsedMSec = 0;
      }else{
        elapsedMSec = now.getTime() - open.getTime()
      }

      this.elapsedDay =  Math.floor(elapsedMSec / 1000 / 60 / 60 / 24);

    },
    dateFormat(date) {
      return moment(date).format("YYYY.MM.DD HH:mm");
    },
    //이미지 불러오기
    getMissingImg(){
      this.lastEventId = this.event.viewId.slice(-1) % 6 ;
      return this.images[this.lastEventId];
    },
    getHomeUrl(){
      /* 현장별 상황판 새 탭일 경우 홈
      var resultUrl = `/${this.accountId}/eventControl/${this.eventId}/SkyView`;
      return resultUrl*/
      var resultUrl = ""

      switch (this.userType){
        case "Admin" :
          resultUrl = `/${this.accountId}/eventControl/liveListControlUser`;
          break;
        case "Manager" :
          resultUrl = `/${this.accountId}/eventControl/liveListControlUser`;
          // resultUrl = `/${this.accountId}/dashboard/DroneStatusMap`;
          break;
        case "User" :
          resultUrl = `/${this.accountId}/eventControl/liveListControlUser`;
          break;
      }

      return resultUrl
    },
    handleOpen() {
      this.isCollapse = !this.isCollapse;
      document.querySelector(".expandBtn").classList.toggle("expand");
      document.querySelector(".lnb").classList.toggle("expand");
    },
    leftOpen() {
      if(this.isCollapse == true){
        this.handleOpen()
      }
    },
    leftClose() {
    },
    getEventDetail() {
      if(this.eventId == undefined) {
        return;
      }
      fetchEvent(this.eventId).then((res) => {
        this.event = res.data.data;
        this.time();
      });
    },
    toggleSubMenu() {
      this.isSubMenuVisible = !this.isSubMenuVisible;
      if (this.isSubMenuVisible) {
        // 한 메뉴가 활성화될 때 다른 메뉴의 활성화 상태를 해제
        this.isSubMenuVisible2 = false;
        // 윈도우 클릭 이벤트 추가
        window.addEventListener('click', this.closeSubMenuOnClickOutside);
      }
      else{window.removeEventListener('click', this.closeSubMenuOnClickOutside);}
    },
    toggleSubMenu2() {
      this.isSubMenuVisible2 = !this.isSubMenuVisible2;
      if (this.isSubMenuVisible2) {
        // 한 메뉴가 활성화될 때 다른 메뉴의 활성화 상태를 해제
        this.isSubMenuVisible = false;
        // 윈도우 클릭 이벤트 추가
        window.addEventListener('click', this.closeSubMenuOnClickOutside2);
      }
      else{window.removeEventListener('click', this.closeSubMenuOnClickOutside2);}
    },
    closeSubMenuOnClickOutside(event) {
      // 만약 클릭된 요소가 서브 메뉴나 해당 토글 버튼이 아니라면 서브 메뉴를 닫습니다.
      const subMenu = document.getElementById('missionSub'); // 서브 메뉴의 ID를 사용하셔야 합니다.
      const toggleButton = document.getElementById('mission'); // 토글 버튼의 ID를 사용하셔야 합니다.

      // 클릭된 요소가 서브 메뉴 안에 있는지 확인
      const clickedInsideSubMenu = subMenu.contains(event.target);

      if (!clickedInsideSubMenu && event.target !== toggleButton) {
        this.isSubMenuVisible = false;
        // 다른 메뉴의 활성화 상태도 여기서 해제해야 한다면 추가해주세요.
        this.isSubMenuVisible2 = false;

        // 윈도우 클릭 이벤트 제거
        window.removeEventListener('click', this.closeSubMenuOnClickOutside);
      }
    },
    closeSubMenuOnClickOutside2(event) {
      // 만약 클릭된 요소가 서브 메뉴나 해당 토글 버튼이 아니라면 서브 메뉴를 닫습니다.
      const subMenu = document.getElementById('mappingSub'); // 서브 메뉴의 ID를 사용하셔야 합니다.
      const toggleButton = document.getElementById('mapping'); // 토글 버튼의 ID를 사용하셔야 합니다.

      // 클릭된 요소가 서브 메뉴 안에 있는지 확인
      const clickedInsideSubMenu = subMenu.contains(event.target);

      if (!clickedInsideSubMenu && event.target !== toggleButton) {
        this.isSubMenuVisible = false;
        // 다른 메뉴의 활성화 상태도 여기서 해제해야 한다면 추가해주세요.
        this.isSubMenuVisible2 = false;

        // 윈도우 클릭 이벤트 제거
        window.removeEventListener('click', this.closeSubMenuOnClickOutside);
      }
    },
    handleSubMenuClick(item) {
      // 서브 메뉴 항목을 클릭할 때 실행되는 로직 추가
        // 여기에 원하는 로직 추가 가능
        item
    },
    // logout() {
    //   let id = this.accountId;
    //   id
    //   this.$store.dispatch("store/LOGOUT");
    //   // this.$router.push(`/${id}/login`);
    //   this.$router.push({ name: 'login' })
    // },
    isMenuItemActive(name) {
      return this.$route.matched.some(record => record.name === name);
    },
    handleMenuItemClick(name) {
      // 메뉴 항목 클릭 시 해당 메뉴 활성화
      this.setActive(name);
    }
  },
};
</script>
<style lang="scss">
.sub-menu-pointer{
  width: 0px;
  height: 0;
  position: absolute;
  right: 0;
  top: 455px;
  border-bottom: 5px solid rgb(255, 255, 255);
  border-top: 5px solid transparent;
  border-left: 5px solid rgb(255, 255, 255);
  border-right: 5px solid transparent;
  transform: rotate(270deg);
}
.sub-menu-pointer2{
  width: 0px;
  height: 0;
  position: absolute;
  right: 0;
  top: 410px;
  border-bottom: 5px solid rgb(255, 255, 255);
  border-top: 5px solid transparent;
  border-left: 5px solid rgb(255, 255, 255);
  border-right: 5px solid transparent;
  transform: rotate(270deg);
}
.userLnb{
  a{
    width: 100%;
    display: inline-block;
    height: 38px;
    line-height: 38px;
    >img{
      margin: 0 14px 0 24px;
      width: 2rem;
      height: 2rem;
    }
    .userLnbNm{
      color: #b9b9b9;
      font-size: 1.2rem;
    }
  }
  .router-link-exact-active,
  .router-link-active{
    .userEdIcon{
      filter: invert(42%) sepia(94%) saturate(2814%) hue-rotate(189deg) brightness(102%) contrast(101%);
    }
    .userLnbNm{
      color: #0790ff;
    }
  }
}
.mission-sub,
.mapping-sub {
  display: none;
}
.new-menu.active{background: #0080FF;}
.new-menu.active .mission-sub,
.new-menu.active .mapping-sub {
  display: block;
}

/*.userProfile > img{width: 30px;height: 30px;}
.drop-menu-btn{position: relative;display: flex;align-items: center;}
.drop-menu{position: absolute;left: 50px ;background: #fff;border-radius: 5px;box-shadow: 1px 1px 5px #939393;top: -85px;width: 230px;}
.drop-menu > .drop-menu-profile{display: flex;justify-content: space-between;align-items: center;border-bottom: 1px solid #ddd;padding: 15px;}
.drop-menu > .drop-menu-profile > img{width: 50px;}
.drop-menu > .drop-menu-profile > .profile-info{text-align: right;}
.drop-menu > .drop-menu-profile > .profile-info > p{font-size: 14px;color: #454545;margin-bottom: 5px;}
.drop-menu > .drop-menu-profile > .profile-info > p > span{background: #0080FF;color: #fff;border-radius: 10px;padding: 2px 10px;font-size: 12px;}
.drop-menu > .drop-menu-profile > .profile-info > .gnb_email{font-size: 12px;color: #969696;margin: 0;}
.drop-menu > ul > li{border-bottom: 1px solid #ddd;text-align: left;}
.drop-menu > ul > li:hover{background: #eee;}
.drop-menu > ul > li:nth-child(2){border: 0;}
.drop-menu > ul > li > a{font-size: 14px !important;color: #858585;padding: 15px 20px;width: 100%;}
.drop-menu > ul > li > a > img{margin-right: 10px;vertical-align: sub;}
.drop-menu > ul > li > button{border: 0;padding: 15px 20px;font-size: 14px !important;font-weight: 300;color: #858585;height: auto;text-align: left;width: 100%;}
.drop-menu > ul > li > button > img{margin-right: 10px;}*/
.el-menu-item{position: relative;}
.el-menu-item-title{position: absolute;background: #1b1d2cd9;padding: 2px 20px;top: 10px;left: 60px;display: none;height: 40px;border-radius: 5px;line-height: 35px;width: 140px;text-align: center;z-index: 100;}
.el-menu-item-title::before{content: '';position: absolute;top: 10px;left: -15px;  width: 0;height: 0;border-bottom: 10px solid transparent;border-top: 10px solid transparent;border-left: 15px solid #1b1d2cd9;border-right: 0px solid transparent;transform: rotate(180deg);}
.el-menu-item-title > span{font-size: 1.4rem;color: #fff;}
.el-menu-item:hover > .el-menu-item-title{display: block;}

.new-menu > a{position: relative;text-align: center;}
.new-menu > a:hover  > .el-menu-item-title{display: block;}
.step-line{width: 70%;height: 1px;background: #858585;margin: 5px auto;}
.notice-link{position: absolute;bottom: 50px;width: 100%;height: 60px;display: flex;align-items: center;justify-content: center;border-top: 1px solid #475775;}
.notice-link:hover{background: #0080FF;}
.notice-link:hover > .el-menu-item-title{display: block;}

.el-submenu.is-opened > .el-submenu__title{background: #0080FF;}
.el-submenu > .el-menu{border-bottom: 1px solid #707070 !important;}

</style>
