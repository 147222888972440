<template>
  <div class="base flex">
    <PopupLnb v-if="navVisible && !lnbComponent"/>
    <PopupLnb2 v-if="navVisible && lnbComponent"/>
    <div class="main">
      <PopupGnb v-if="gnbVisible" />
        <Title />
        <router-view></router-view>
    </div>
  </div>
</template>
<script>
import { format } from "@/mixins/format";
import { setNavbar } from "@/mixins/setNavbar";
import { setFooter } from "@/mixins/setFooter";
import { setGnb } from "@/mixins/setGnb";
import PopupLnb from "@/components/Lnb_SiteControl";
import PopupLnb2 from "@/components/Lnb_SiteControl2";
import PopupGnb from "@/components/Gnb_SiteControl";
import Title from "@/views/eventController/LiveSiteMonitoringTitle";
import { fetchEvent } from "@/api/event";
import { mapState } from "vuex";



export default {
  mixins: [format, setNavbar, setFooter, setGnb],
  components: { PopupLnb, PopupLnb2, PopupGnb, Title},
  data() {
    return {
      eventId: null,
      event: {},
      department: {},
    };
  },
  created() {
    this.id = this.$route.params.eventId;
    this.$store.dispatch("store/SET_NAVBAR", { nav: 2, sub: 0 });
  },
  async mounted() {
    this.getEventDetail();
    if (this.$route.query.new) {
      this.setNavbar(false);
      this.setGnb(false);
    }
    // this.setNavbar(false);
    this.setFooter(false);
  },
  computed: {
    ...mapState({
      navVisible: state => state.store.navVisible,
      gnbVisible: state => state.store.gnbVisible,
      siteVisible: state => state.store.siteVisible,
    }),
    lnbComponent() {
      if (
        this.$route.name === "Mission" || 
        this.$route.name === "MissionDetail" || 
        this.$route.name === "MissionUpdate" || 
        this.$route.name === "MissionCreate" || 
        this.$route.name === "Mapping" || 
        this.$route.name === "MappingDetail" || 
        this.$route.name === "MappingUpdate" || 
        this.$route.name === "MappingCreate" || 
        this.$route.name === "SchedulePlan" ||
        this.$route.name === "SchedulePlanCreate" ||
        this.$route.name === "SchedulePlanUpdate" ||
        this.$route.name === "ScheduleHistory" ||
        this.$route.name === "ScheduleHistoryDetail" ) {
        return true; // Use PopupLnb2 component
      } else {
        return false; // Default to PopupLnb component
      }
    },
  },
  methods:{
    goHome() {
      this.visible = 1;
    },
    preset(){
   /*   let id = localStorage.getItem("LiveEventInfoMain");
      if(id != undefined) {
        this.visible = id;
      } else {
        if(this.visible == null){
          this.visible = 1;
        }
        localStorage.setItem("LiveEventInfoMain", this.visible);
      }*/
    },
    getEventDetail() {
      if(this.id == undefined) {
        return;
      }
      fetchEvent(this.id).then((res) => {
        this.event = res.data.data;
        this.department = res.data.data.managerDepartment;
        if(!this.$store.state.store.isAdmin && this.event.managerDepartment.departmentId != this.$store.state.store.departmentId){
          var filter = this.event.departmentList.filter(item => item.departmentId == this.$store.state.store.departmentId);
          if(this.event.isAuth == true && filter.length == 0) {
            alert(this.$t("user-not-permission"));
            //this.$router.push({path:"liveListControl"});
          }
        }
        this.preset();
      });
    },
  },
  beforeDestroy() {
    this.setNavbar(true);
    this.setGnb(true);
    this.setFooter(true);
  },
};
</script>
